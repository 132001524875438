import React from "react"
import Proptypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { condition } from "../theme"

const PrimaryStyled = styled.h1`
  color: ${props => props.color || props.theme.colors.primary};
  margin: ${props => props.margin || `24px 0`};
  font-size: ${props => props.theme.fonts.sizes.x_large};
  font-weight: 500;
  text-align: center;
  line-height: ${props => props.lineHeight || ``};
  width: 100%;
  word-break: break-word;

  ${breakpoint(`medium`)`
    margin: ${props => props.margin || `33px 0`};
    font-size: ${props => props.size || props.theme.fonts.sizes.xxx_large};
  `}

  p {
    margin: 0;
  }
`

export const Primary = function ({ children, ...props }) {
  return <PrimaryStyled {...props}>{children}</PrimaryStyled>
}

Primary.propTypes = {
  children: Proptypes.any,
}

const SecondaryStyled = styled.h2`
  font-weight: ${props => props.bold || 900};
  font-size: ${props => props.size || props.theme.fonts.sizes.x_large};
  color: ${props => props.color || props.theme.colors.secondary};
  text-align: ${condition(`center`, `center`)};
  margin-top: ${props => props.marginTop || `24px`};
  margin-bottom: ${props => props.marginBottom || `24px`};
  width: 100%;
  word-break: break-word;

  ${breakpoint(`medium`)`
    font-weight: 500;
    font-size: ${props => props.size || props.theme.fonts.sizes.xxx_large};
    margin-top: ${props => props.marginTop || `32px`};
    margin-bottom: ${props => props.marginBottom || `32px`};
  `}
`

export const Secondary = function ({ component = `h2`, children, ...props }) {
  return (
    <SecondaryStyled as={component} {...props}>
      {children}
    </SecondaryStyled>
  )
}

Secondary.propTypes = {
  component: Proptypes.string,
  children: Proptypes.any.isRequired,
}
